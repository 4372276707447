<template>

  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <b-img :src="logo_img" width="60" />
        </h2>
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-primary"
          >
            {{$t('Reset Password')}} 🔒
          </b-card-title>
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="submit"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="resetpassword-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="resetpassword-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    name="resetpassword-email"
                    placeholder="store@mhd.sa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('Password')"
                label-for="resetpassword-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="resetpassword-password"
                      type="password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="resetpassword-password"
                      placeholder="············"
                    />

                  </b-input-group>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('Confirm Password')"
                label-for="resetpassword-confirm-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="resetpassword-confirm-password"
                      type="password"
                      v-model="form.password_confirmation"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="resetpassword-confirm-password"
                      placeholder="············"
                    />

                  </b-input-group>
                </validation-provider>
              </b-form-group>


              <b-button
                type="submit"
                variant="primary"
                block
              >
                <template v-if="!isLoading">
                  {{$t('Send')}}
                </template>
                <template v-if="isLoading">
                  {{$t('Sending')}}...
                  <b-spinner small />
                </template>
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{$t('Sign in')}}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import useJwt from '@/auth/useJwt'
import store from '@/store/'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  name:'Login',
  mounted() {
    localize('ar',{
      messages: {
        required: 'هذا الحقل مطلوب',
        email: 'يرجى إدخال بريد إلكتروني صحيح',
      },
    })
    this.$i18n.locale = 'ar'
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v22.png'),
      form:{
        email:'',
        password:'',
        password_confirmation:'',
      },
      resetPassword:{
        email:''
      },
      showError:false,
      isLoading: false,
      hasStores: false,
      isResetPassword: true,
      loginResponse: {},
      logo_img: require('@/assets/images/logo/logo.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v22.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    submit: function () {
      this.isLoading = true;
      this.form.token = this.$route.params.token
      useJwt.post('/reset-password',this.form)
      .then(response => {      
        if (response.data.data.reset == false) {
          this.isLoading = false;
          this.$root.showAlert('error', '')
          return;
        }  
        window.location.href = '/login';
      })
      .catch(error => {
        this.isLoading = false;
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
